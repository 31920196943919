@import '../../@framework/components/TabContainer/tabPanel.component.less';
                @import '../../@framework/components/TabContainer/tabView.component.less';













.tabcontainer-global-variables() {
                  .global-variables();
                  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @Shadow: @default-shadow;
  @BackgroundColor: transparent;

                }

                .zapp-tabcontainer  {
                  .tabcontainer-global-variables();
                  
                  
                  
                  .tabcontainer-status-mixin();
}

.tabcontainer-status-default {
  .global-variables();
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @Shadow: @default-shadow;
  @BackgroundColor: transparent;
  @MarginBottom: 10px;
  @MarginTop: 10px;



  .tabcontainer-status-mixin();
}

.tabcontainer-status-primary {
  .global-variables();
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @Shadow: @default-shadow;
  @BackgroundColor: transparent;
  @MarginBottom: 10px;
  @MarginTop: 10px;



  .tabcontainer-status-mixin();
}

.tabcontainer-status-info {
  .global-variables();
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @Shadow: @default-shadow;
  @BackgroundColor: transparent;
  @MarginBottom: 10px;
  @MarginTop: 10px;



  .tabcontainer-status-mixin();
}

.tabcontainer-status-success {
  .global-variables();
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @Shadow: @default-shadow;
  @BackgroundColor: transparent;
  @MarginBottom: 10px;
  @MarginTop: 10px;



  .tabcontainer-status-mixin();
}

.tabcontainer-status-warning {
  .global-variables();
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @Shadow: @default-shadow;
  @BackgroundColor: transparent;
  @MarginBottom: 10px;
  @MarginTop: 10px;



  .tabcontainer-status-mixin();
}

.tabcontainer-status-danger {
  .global-variables();
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @Shadow: @default-shadow;
  @BackgroundColor: transparent;
  @MarginBottom: 10px;
  @MarginTop: 10px;



  .tabcontainer-status-mixin();
}







.tabheader-global-variables() {
                  .global-variables();
                  @ActiveBackgroundColor: @containers-color;
  @ActiveColor: @font-color;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @ForegroundColor: @font-color;
  @HoverBackgroundColor: @containers-color;
  @HoverColor: @font-color;
  @ActiveWeight: bold;
  @BackgroundColor: transparent;
  @BorderBottomStyle: solid;
  @BorderBottomWidth: 1px;
  @BorderLeftStyle: solid;
  @BorderLeftWidth: 1px;
  @BorderRightStyle: solid;
  @BorderRightWidth: 1px;
  @BorderTopStyle: solid;
  @BorderTopWidth: 1px;

                }

                .zapp-tabheader  {
                  .tabheader-global-variables();
                  
                  
                  
                  .tabheader-status-mixin();
}

.tabheader-status-default {
  .global-variables();
  @ActiveBackgroundColor: @color-default;
  @ActiveColor: @color-default-contrast;
  @BorderBottomColor: @color-default;
  @BorderLeftColor: @color-default;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @color-default;
  @BorderTopColor: @color-default;
  @ForegroundColor: @color-default;
  @HoverBackgroundColor: @color-default;
  @HoverColor: @color-default-contrast;
  @ActiveWeight: bold;
  @BackgroundColor: transparent;
  @BorderBottomStyle: solid;
  @BorderBottomWidth: 1px;
  @BorderLeftStyle: solid;
  @BorderLeftWidth: 1px;
  @BorderRightStyle: solid;
  @BorderRightWidth: 1px;
  @BorderTopStyle: solid;
  @BorderTopWidth: 1px;



  .tabheader-status-mixin();
}

.tabheader-status-primary {
  .global-variables();
  @ActiveBackgroundColor: @color-primary;
  @ActiveColor: @color-primary-contrast;
  @BorderBottomColor: @color-primary;
  @BorderLeftColor: @color-primary;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @color-primary;
  @BorderTopColor: @color-primary;
  @ForegroundColor: @color-primary;
  @HoverBackgroundColor: @color-primary;
  @HoverColor: @color-primary-contrast;
  @ActiveWeight: bold;
  @BackgroundColor: transparent;
  @BorderBottomStyle: solid;
  @BorderBottomWidth: 1px;
  @BorderLeftStyle: solid;
  @BorderLeftWidth: 1px;
  @BorderRightStyle: solid;
  @BorderRightWidth: 1px;
  @BorderTopStyle: solid;
  @BorderTopWidth: 1px;



  .tabheader-status-mixin();
}

.tabheader-status-info {
  .global-variables();
  @ActiveBackgroundColor: @color-info;
  @ActiveColor: @color-info-contrast;
  @BorderBottomColor: @color-info;
  @BorderLeftColor: @color-info;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @color-info;
  @BorderTopColor: @color-info;
  @ForegroundColor: @color-info;
  @HoverBackgroundColor: @color-info;
  @HoverColor: @color-info-contrast;
  @ActiveWeight: bold;
  @BackgroundColor: transparent;
  @BorderBottomStyle: solid;
  @BorderBottomWidth: 1px;
  @BorderLeftStyle: solid;
  @BorderLeftWidth: 1px;
  @BorderRightStyle: solid;
  @BorderRightWidth: 1px;
  @BorderTopStyle: solid;
  @BorderTopWidth: 1px;



  .tabheader-status-mixin();
}

.tabheader-status-success {
  .global-variables();
  @ActiveBackgroundColor: @color-success;
  @ActiveColor: @color-success-contrast;
  @BorderBottomColor: @color-success;
  @BorderLeftColor: @color-success;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @color-success;
  @BorderTopColor: @color-success;
  @ForegroundColor: @color-success;
  @HoverBackgroundColor: @color-success;
  @HoverColor: @color-success-contrast;
  @ActiveWeight: bold;
  @BackgroundColor: transparent;
  @BorderBottomStyle: solid;
  @BorderBottomWidth: 1px;
  @BorderLeftStyle: solid;
  @BorderLeftWidth: 1px;
  @BorderRightStyle: solid;
  @BorderRightWidth: 1px;
  @BorderTopStyle: solid;
  @BorderTopWidth: 1px;



  .tabheader-status-mixin();
}

.tabheader-status-warning {
  .global-variables();
  @ActiveBackgroundColor: @color-warning;
  @ActiveColor: @color-warning-contrast;
  @BorderBottomColor: @color-warning;
  @BorderLeftColor: @color-warning;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @color-warning;
  @BorderTopColor: @color-warning;
  @ForegroundColor: @color-warning;
  @HoverBackgroundColor: @color-warning;
  @HoverColor: @color-warning-contrast;
  @ActiveWeight: bold;
  @BackgroundColor: transparent;
  @BorderBottomStyle: solid;
  @BorderBottomWidth: 1px;
  @BorderLeftStyle: solid;
  @BorderLeftWidth: 1px;
  @BorderRightStyle: solid;
  @BorderRightWidth: 1px;
  @BorderTopStyle: solid;
  @BorderTopWidth: 1px;



  .tabheader-status-mixin();
}

.tabheader-status-danger {
  .global-variables();
  @ActiveBackgroundColor: @color-danger;
  @ActiveColor: @color-danger-contrast;
  @BorderBottomColor: @color-danger;
  @BorderLeftColor: @color-danger;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @color-danger;
  @BorderTopColor: @color-danger;
  @ForegroundColor: @color-danger;
  @HoverBackgroundColor: @color-danger;
  @HoverColor: @color-danger-contrast;
  @ActiveWeight: bold;
  @BackgroundColor: transparent;
  @BorderBottomStyle: solid;
  @BorderBottomWidth: 1px;
  @BorderLeftStyle: solid;
  @BorderLeftWidth: 1px;
  @BorderRightStyle: solid;
  @BorderRightWidth: 1px;
  @BorderTopStyle: solid;
  @BorderTopWidth: 1px;



  .tabheader-status-mixin();
}







.tabcontent-global-variables() {
                  .global-variables();
                
                }

                .zapp-tabcontent  {
                  .tabcontent-global-variables();
                  
                  
                  
                  .tabcontent-status-mixin();
}

.tabcontent-status-default {
  .global-variables();



  .tabcontent-status-mixin();
}

.tabcontent-status-primary {
  .global-variables();



  .tabcontent-status-mixin();
}

.tabcontent-status-info {
  .global-variables();



  .tabcontent-status-mixin();
}

.tabcontent-status-success {
  .global-variables();



  .tabcontent-status-mixin();
}

.tabcontent-status-warning {
  .global-variables();



  .tabcontent-status-mixin();
}

.tabcontent-status-danger {
  .global-variables();



  .tabcontent-status-mixin();
}







.tabpage-global-variables() {
                  .global-variables();
                  @BackgroundColor: @containers-color;
  @BorderBottomColor: @border-color;
  @BorderLeftColor: @border-color;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @border-color;
  @BorderTopColor: @border-color;
  @ForegroundColor: @font-color;
  @BorderBottomStyle: solid;
  @BorderBottomWidth: 1px;
  @BorderLeftStyle: solid;
  @BorderLeftWidth: 1px;
  @BorderRightStyle: solid;
  @BorderRightWidth: 1px;
  @BorderTopStyle: solid;
  @BorderTopWidth: 1px;
  @PaddingBottom: 10px;
  @PaddingLeft: 10px;
  @PaddingRight: 10px;
  @PaddingTop: 10px;

                }

                .zapp-tabpage  {
                  .tabpage-global-variables();
                  
                  
                  
                  .tabpage-status-mixin();
}

.tabpage-status-default {
  .global-variables();
  @BackgroundColor: @color-default;
  @BorderBottomColor: @color-default;
  @BorderLeftColor: @color-default;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @color-default;
  @BorderTopColor: @color-default;
  @ForegroundColor: @color-default-contrast;
  @BorderBottomStyle: solid;
  @BorderBottomWidth: 1px;
  @BorderLeftStyle: solid;
  @BorderLeftWidth: 1px;
  @BorderRightStyle: solid;
  @BorderRightWidth: 1px;
  @BorderTopStyle: solid;
  @BorderTopWidth: 1px;
  @PaddingBottom: 10px;
  @PaddingLeft: 10px;
  @PaddingRight: 10px;
  @PaddingTop: 10px;



  .tabpage-status-mixin();
}

.tabpage-status-primary {
  .global-variables();
  @BackgroundColor: @color-primary;
  @BorderBottomColor: @color-primary;
  @BorderLeftColor: @color-primary;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @color-primary;
  @BorderTopColor: @color-primary;
  @ForegroundColor: @color-primary-contrast;
  @BorderBottomStyle: solid;
  @BorderBottomWidth: 1px;
  @BorderLeftStyle: solid;
  @BorderLeftWidth: 1px;
  @BorderRightStyle: solid;
  @BorderRightWidth: 1px;
  @BorderTopStyle: solid;
  @BorderTopWidth: 1px;
  @PaddingBottom: 10px;
  @PaddingLeft: 10px;
  @PaddingRight: 10px;
  @PaddingTop: 10px;



  .tabpage-status-mixin();
}

.tabpage-status-info {
  .global-variables();
  @BackgroundColor: @color-info;
  @BorderBottomColor: @color-info;
  @BorderLeftColor: @color-info;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @color-info;
  @BorderTopColor: @color-info;
  @ForegroundColor: @color-info-contrast;
  @BorderBottomStyle: solid;
  @BorderBottomWidth: 1px;
  @BorderLeftStyle: solid;
  @BorderLeftWidth: 1px;
  @BorderRightStyle: solid;
  @BorderRightWidth: 1px;
  @BorderTopStyle: solid;
  @BorderTopWidth: 1px;
  @PaddingBottom: 10px;
  @PaddingLeft: 10px;
  @PaddingRight: 10px;
  @PaddingTop: 10px;



  .tabpage-status-mixin();
}

.tabpage-status-success {
  .global-variables();
  @BackgroundColor: @color-success;
  @BorderBottomColor: @color-success;
  @BorderLeftColor: @color-success;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @color-success;
  @BorderTopColor: @color-success;
  @ForegroundColor: @color-success-contrast;
  @BorderBottomStyle: solid;
  @BorderBottomWidth: 1px;
  @BorderLeftStyle: solid;
  @BorderLeftWidth: 1px;
  @BorderRightStyle: solid;
  @BorderRightWidth: 1px;
  @BorderTopStyle: solid;
  @BorderTopWidth: 1px;
  @PaddingBottom: 10px;
  @PaddingLeft: 10px;
  @PaddingRight: 10px;
  @PaddingTop: 10px;



  .tabpage-status-mixin();
}

.tabpage-status-warning {
  .global-variables();
  @BackgroundColor: @color-warning;
  @BorderBottomColor: @color-warning;
  @BorderLeftColor: @color-warning;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @color-warning;
  @BorderTopColor: @color-warning;
  @ForegroundColor: @color-warning-contrast;
  @BorderBottomStyle: solid;
  @BorderBottomWidth: 1px;
  @BorderLeftStyle: solid;
  @BorderLeftWidth: 1px;
  @BorderRightStyle: solid;
  @BorderRightWidth: 1px;
  @BorderTopStyle: solid;
  @BorderTopWidth: 1px;
  @PaddingBottom: 10px;
  @PaddingLeft: 10px;
  @PaddingRight: 10px;
  @PaddingTop: 10px;



  .tabpage-status-mixin();
}

.tabpage-status-danger {
  .global-variables();
  @BackgroundColor: @color-danger;
  @BorderBottomColor: @color-danger;
  @BorderLeftColor: @color-danger;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @color-danger;
  @BorderTopColor: @color-danger;
  @ForegroundColor: @color-danger-contrast;
  @BorderBottomStyle: solid;
  @BorderBottomWidth: 1px;
  @BorderLeftStyle: solid;
  @BorderLeftWidth: 1px;
  @BorderRightStyle: solid;
  @BorderRightWidth: 1px;
  @BorderTopStyle: solid;
  @BorderTopWidth: 1px;
  @PaddingBottom: 10px;
  @PaddingLeft: 10px;
  @PaddingRight: 10px;
  @PaddingTop: 10px;



  .tabpage-status-mixin();
}








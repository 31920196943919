@import '../../@framework/components/Body/Body.component.less';
.body-global-variables() {
                  .global-variables();
                  @BackgroundColor: @containers-color;
  @FontFamily: @main-font;
  @ForegroundColor: @font-color;

                }

                .zapp-body  {
                  .body-global-variables();
                  
                  
                  
                  .body-status-mixin();
}


body {
.body-global-variables();
  @BackgroundColor: @containers-color;
  @FontFamily: @main-font;
  @ForegroundColor: @font-color;


.body-status-mixin();
}


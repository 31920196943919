@import '../../@framework/components/Breadcrumb/Breadcrumb.component.less';

.breadcrumbcontrol-global-variables() {
                  .global-variables();
                  @BackgroundColor: @containers-color;
  @ForegroundColor: @font-color;
  @Shadow: @default-shadow;
  @BorderLeftWidth: 0px;
  @BorderRadiusBottomLeft: 0rem;
  @BorderRadiusBottomRight: 0rem;
  @BorderRadiusTopLeft: 0rem;
  @BorderRadiusTopRight: 0rem;
  @BorderRightWidth: 0px;
  @BorderTopWidth: 0px;

                }

                .zapp-breadcrumbcontrol  {
                  .breadcrumbcontrol-global-variables();
                  
                  
                  
                  .breadcrumbcontrol-status-mixin();
}

.breadcrumbcontrol-status-default {
  .global-variables();
  @BackgroundColor: @color-default;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @ForegroundColor: @color-default-contrast;
  @Shadow: @default-shadow;
  @Height: 50px;



  .breadcrumbcontrol-status-mixin();
}

.breadcrumbcontrol-status-primary {
  .global-variables();
  @BackgroundColor: @color-primary;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @ForegroundColor: @color-primary-contrast;
  @Shadow: @default-shadow;
  @Height: 50px;



  .breadcrumbcontrol-status-mixin();
}

.breadcrumbcontrol-status-info {
  .global-variables();
  @BackgroundColor: @color-info;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @ForegroundColor: @color-info-contrast;
  @Shadow: @default-shadow;
  @Height: 50px;



  .breadcrumbcontrol-status-mixin();
}

.breadcrumbcontrol-status-success {
  .global-variables();
  @BackgroundColor: @color-success;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @ForegroundColor: @color-success-contrast;
  @Shadow: @default-shadow;
  @Height: 50px;



  .breadcrumbcontrol-status-mixin();
}

.breadcrumbcontrol-status-warning {
  .global-variables();
  @BackgroundColor: @color-warning;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @ForegroundColor: @color-warning-contrast;
  @Shadow: @default-shadow;
  @Height: 50px;



  .breadcrumbcontrol-status-mixin();
}

.breadcrumbcontrol-status-danger {
  .global-variables();
  @BackgroundColor: @color-danger;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @ForegroundColor: @color-danger-contrast;
  @Shadow: @default-shadow;
  @Height: 50px;



  .breadcrumbcontrol-status-mixin();
}








@import './app/@theming/themes/default.less';
@import './app/@theming/global/components.less';
@import './primeng.min.css';
@import './lara-light-blue.theme.css';
@import './primeflex.min.css';

// Theme LESS Code & User LESS Code
@import "./assets/libraries/primeicons/primeicons.css";

html { 
    font-size: 14px; 
}

.full-height-tabs .p-tabview .p-tabview-panels {
    overflow: auto;
    height: calc(100vh - 200px);
}

.p-field-checkbox {
    margin-bottom: 0px !important;
}

zappformgroup.horizontal {
    align-items: center;
}

.p-input-icon-right > i:nth-last-of-type(2) {
  right: 2rem;
  color: #6c757d;
}

.p-input-icon-right > i:nth-last-of-type(3) {
  right: 3.5rem;
}

.list-status-default table.dataTable,
.list-status-default table.dataTable.display {
    text-align: left;
}

.master-page-layout {
  display: block;
  padding: 0px;
  margin: 0px 2rem;
}

&.required {
    position: relative;
    
    &::after {
      content: ".";
      position: absolute;
      top: -1.25rem;
      right: 0.25rem;
      line-height: 0rem;
      font-size: 3.5rem;
      color: red;
    }
    
    &.zapp-textarea::after {
        top: -0.75rem;
    }
    
    &.zapp-richtextbox::after {
        top: -0.5rem;
    }
}

.zapp-button a label {
  color: @color-default !important;
}

.p-container {
    padding: 1.5rem 3rem 3rem 3rem;
    
    &.fixed-width {
        width: 30rem;
    }
}

.with-right-element {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.p-panel-header {
    .push-right {
        margin-left: auto;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {  
 .container {
     width: 540px;
 }
}
 
/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  
 .container {
     width: 720px;
 }
}
 
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
 .container {
     width: 960px;
 }
}
 
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
    .container {
     width: 1140px;
 }
}


.bg-z-primary {
    background-color: @color-primary;
}

.app-container {
    display: flex;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
    position: sticky;
    
    .pending-changes {
        font-weight: bold;
        display: inline-block;
        position: fixed;
        right: 30px;
        top: 50px;
        background-color: @header-bg;
        color: @color-primary;
        padding: 0 10px;
        border-radius: 4px;
        z-index: 201;
    }
    
    .menu-container {
        display: flex;
        min-width: 250px;
        
        zapp-menu {
            width: 100%;
        }
    }
    
    .app-body-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: auto;
        
        zapp-breadcrumb {

        }
    }
    
    .actions-container {
        position:fixed;
        right: 65px;
        top: 5px;
        z-index: 201;
    }
    
    .master-page-footer {
        position: sticky;
        left: 0;
        right: 0;
        bottom: 0;
        height: 25px;
        line-height: 25px;
        z-index: 10;
        background-color: @color-primary;
        
        label {
            color: @color-default-contrast !important;
        }
    }
}

.bg-color-indicator {
    width: 24px;
    height: 24px;
    border: 2px black solid;
    border-radius: 4px;
    display: block;
}


zappdevpicklist > zapp-button > a {
    text-decoration: none;
    cursor: pointer;
    
    label {
        color: @font-color !important;
    }
}

.zapp-button:hover button > label, .zapp-button:hover button span, .zapp-button:hover button i {
    color: @containers-color;
}

.custom-tag-container {
    
    gap: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    
    .custom-tag {
        padding: 0.2rem 0.5rem;
        border-radius: 0.5rem;
        /* max-width: 90px; */
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

//Notifications

.app-header {
    
    position: relative;
    margin-bottom: 1.5rem;
    position: sticky;
    top: 0;
    z-index: 200;
            
    .notification-badge {
        
        border-radius: 25px;
        padding: 10px;
        background-color: @bg-color;
        z-index: 201;
        position: absolute;
        top: 5px;
        right: 5px;
        
        &:hover {
            cursor: pointer;
            background-color: darken(@bg-color, 6);
        }
        
        .p-badge {
            font-size: 0.75rem;
            font-weight: 500;
            min-width: 0.5rem;
            height: 0.5rem;
            line-height: 0.5rem;
            margin-right: 13px;
            margin-top: 10px;
            
        }
        
    }
    
    .p-overlaypanel {
        //margin-top: 22px !important;
    }
    
    .p-overlaypanel .p-overlaypanel-close {
        z-index: 1;
    }
        
    .p-overlaypanel .p-overlaypanel-content {
        padding: 1rem;
        overflow: auto;
        max-height: 80vh;
    
    }
    
    .notification-none {
        font-size: 17px;
        font-weight: 500;
        text-align: center;
    }
    
    .notification-entry {
        
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0px 10px;
        border-radius: @default-radius;
        background-color: fade(white, 10%);
        color: @color-default-contrast;
        
        .notification-entry-content {
            display: flex;
            flex-direction: column;
            padding: 7px;
        }
        
        label {
            cursor: pointer;
        }
        
        &:hover {
            background-color: #caced533;
            cursor: pointer;
            border-radius: 5px;
        }
    
    
        .notification-entry-date{
            font-size: 10px;
            display: flex;
            justify-content: flex-start;
            opacity: 0.5
        }    
        
        .notification-status{
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            background-color: @color-info;
        }
    }
}

.side-panel-container {
    position: fixed;
    top: 44px;
    right: 20px;
    z-index: 300;
    width: 400px;
    height: 550px;
    overflow: auto;
    border: 2px solid @border-color;
    border-radius: 4px;
    padding: 0.5rem;
    background: whitesmoke;
    
    
    .notification-entry {
        cursor: pointer !important;
        padding: 0.3rem;
        border-radius: 4px;
        border: 1px solid @border-color;
        background: white;
        opacity: 0.9;
        position: relative;
        margin-bottom: 6px;
        
        &:hover {
            opacity: 1;
        }
        
        .notification-entry-date {
            display: block;
            text-align: right;
            margin-top: 3px;
        }
        
        label {
            cursor: pointer !important;
        }
        
        .notification-status {
            displau: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 10px;
            background: @color-danger;
            position: absolute;
            top: 4px;
            right: 4px;
        }
    }
}

